import styled from "styled-components";

export const WorksitePanelStyled = styled.div`
  .assetMobileCss {
    background-color: ${({ theme }) => theme.colors.themePrimary || "#0d5fbe"};
  }
  .filterButtonCss {
    background-color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
    border: 1px solid ${({ theme }) => theme.colors.grayL5 || "#CCCCCC"};
  }
`;

export const FilterIconStyled = styled.div`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.themePrimary || "#0d5fbe"};
  position: relative;
  top: 3px;
`;

export const DesktopView = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

export const MobileView = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`;

export const ActionBarWrapper = styled.div`
  position: relative;
`;
export const ActionBarButtons = styled.div`
  position: absolute;
  z-index: 5;
  width: 100%;
  background: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 37%);
  -webkit-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 37%);
  -moz-box-shadow: 0px 0px 5px 0px rgb(0 0 0 / 37%);
  display: block;
  button {
    border: 0px;
    display: block;
    width: 100%;
    float: left;
    text-align: left;
    color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
    text-transform: capitalize;
    font-size: 16px;
    font-family: "Open Sans";
    i {
      display: none;
    }
    &:disabled {
      border: 0px;
    }
  }
`;

export const SearchBoxWrapper = styled.div`
  flex: 1;
`;

export const SearchFilter = styled.div`
  display: flex;
  margin-top: 15px;
`;
export const FilterWrapper = styled.div`
  flex: 0;
`;

export const CloseBackDiv = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL1 || "#323232"};
  border-radius: 4px;
  margin-left: 10px;
  height: 36px;
  width: 42px;
  align-items: center;
  justify-content: center;
  display: flex;
  background: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
`;
export const FilterBackDiv = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.grayL1 || "#323232"};
  border-radius: 4px;
  margin-left: 10px;
  height: 36px;
  width: 42px;
  align-items: center;
  justify-content: center;
  display: flex;
`;
export const FilterIconDiv = styled.div`
  font-size: 20px !important;
  display: flex;
  color: ${({ theme }) => theme.colors.grayL1 || "#323232"};
`;

export const CloseIconDiv = styled.div`
  font-size: 20px !important;
  display: flex;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;

export const FilterOnclickDes = styled.div``;
export const FilterDesWrapper = styled.div`
  margin-bottom: 10px;
  @media (max-width: 1100px) {
    .col {
      padding-left: 0px;
      padding-right: 0px;
    }
  }
`;

export const WorsiteDetailHeading = styled.div`
  text-align: left;
  float: left;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.themePrimary || "#0D5FBE"};
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
  line-height: 45px;
  padding-left: 9px;
  font-size: 16px !important;
  font-weight: 500;
  border-radius: 4px;
  font-family: "Open Sans", sans-serif;

  span {
    font-weight: 500;
  }

  img {
    margin-right: 8px;
    margin-left: 8px;
  }
`;

export const RightIconImage = styled.div`
  font-size: 20px !important;
  display: inline;
  line-height: 12px;
  margin-left: 5px;
  margin-right: 5px;
  color: ${({ theme }) => theme.colors.grayL7 || "#FFFFFF"};
`;

export const WorksiteMobileListWrapper = styled.div``;
