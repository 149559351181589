/**
 * TODO:  Create a common filter component for all the reports/Dashboard pages when time permits
 * Do code optimization
 * Fix lint issues
 * Once completed, please remove this comment
 * Create common components
 */

import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { getZipDownloadReport } from "../../../../redux/reports/action";
import { setDropdownAccountId, setDropdownWorksiteId } from "../../../../redux/dropdownFilters/action";
import {
  getInspectionReportListing,
  exportAllInspectionCSVResetState,
} from "../../../../redux/reports/action";
import { setInspectionReportFilter } from "../../../../redux/filters/action";
import { updateActiveNavItem } from "../../../../redux/sideMenu/menu.action";
import { exportAllInspectionCSV } from "../../../../redux/reports/action";
import { toDownloadFiles } from "../../../../redux/config/action";
import { apiCall } from "../../../../utils/apiCall";
import DownloadAllReportModal from "../../Modals/DownloadAllReportModal";
import { paginationOptions } from "./constants";
import { InspectionReportDiv } from "../Reports.styles";
import SaveToOptions from "./components/SaveToOptions.component";
import Filters from "./components/Filters.component";
import { CacheRefs } from "../../../../utils/cache/CacheRefs";
import InspectionReportsDataTable from "./TableWrapper/TableWrapper.component";

function InspectionReports(props) {
  const {
    loginDetails,
    isSuperAdmin,
    isSiteAdmin,
    getInspectionReportListing,
    inspectionReportList,

    totalInspectionReport,
    getZipDownloadReport,
    getZipFileReportLoading,
    setInspectionReportFilter,
    inspectionReportFilter,
    dropdownAssetTypeList,
    dropdownAssetList,
    dropdownFilterAccountId,
    dropdownFilterWorksiteId,
    exportAllInspectionCSV,
    exportAllInspectionCSVLoading,
    exportAllInspectionCSVSuccess,
    exportAllInspectionCSVResetState,
    currentTimeZone,
  } = props;

  const [lastFilterData, setLastFilterData] = useState([]);
  const [downloadReportSet, addDownloadReportSet] = useState(new Set());
  const [currentActiveKey, setCurrentActiveKey] = useState("");
  const [isAllSelected, setSelectAll] = useState(false);
  const [isDownloadAllSelected, setIsDownloadAllSelected] = useState(false);
  const [showDownloadAllReportModal, setShowDownloadAllReportModal] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);

  const { endDate, pageNo, pageSize, startDate } = inspectionReportFilter;

  useEffect(() => {
    setInspectionReportFilter({ ...inspectionReportFilter, worksiteId: "all" });
    if (inspectionReportFilter.accountId === "all") {
      setSelectedAccount(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionReportFilter.accountId]);

  useEffect(() => {
    setInspectionReportFilter({
      ...inspectionReportFilter,
      assetTypeId: "all",
      assetId: "all",
      inspectorId: "all",
      status: "all",
      checklistId: "all",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionReportFilter.filterByType]);

  const handleCheckboxClick = (e) => {
    const newDownloadReportSet = new Set(downloadReportSet);

    if (e.target.checked) {
      newDownloadReportSet.add(e.target.value);
    } else {
      newDownloadReportSet.delete(e.target.value);
    }
    addDownloadReportSet(newDownloadReportSet);
  };

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const newDownloadReportSet = new Set(downloadReportSet);
      inspectionReportList.forEach((data, i) => {
        newDownloadReportSet.add(data._id);
      });
      addDownloadReportSet(newDownloadReportSet);
      setSelectAll(true);
    } else {
      const newDownloadReportSet = new Set();
      addDownloadReportSet(newDownloadReportSet);
      setSelectAll(false);
    }
  };

  useEffect(() => {
    setInspectionReportFilter({
      ...inspectionReportFilter,
      assetId: "all",
      assetTypeId: "all",
      inspectorId: "all",
      status: "all",
      checklistId: "all",
      pageNo: 1,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownFilterAccountId]);

  useEffect(() => {
    if (inspectionReportFilter.filterByType === "asset") {
      setLastFilterData(dropdownAssetList);
    } else if (inspectionReportFilter.filterByType === "assetType") {
      setLastFilterData(dropdownAssetTypeList);
    } else if (inspectionReportFilter.filterByType === "inspectedBy") {
      // New backend api
      const url = `/dropdown/users?accid=${
        isSuperAdmin ? inspectionReportFilter.accountId : loginDetails.accountId
      }&siteid=${
        isSiteAdmin ? loginDetails?.userData?.authorizedSites?.[0] : dropdownFilterWorksiteId
      }&status=true`;
      apiCall(url).then((resp) => {
        setLastFilterData(resp.data?.data?.data);
      });
    } else if (inspectionReportFilter.filterByType == "inspectionResult") {
      setLastFilterData([
        { fullName: "Pass", _id: "passed" },
        { fullName: "Fail", _id: "failed" },
        { fullName: "Timed out", _id: "timedout" },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownAssetTypeList, dropdownAssetList]);

  const handlePageLength = (e) => {
    addDownloadReportSet(new Set());
    setInspectionReportFilter({
      ...inspectionReportFilter,
      pageSize: e.target.value,
      pageNo: 1,
    });
  };

  const nextPage = () => {
    if (pageNo > Math.ceil((totalInspectionReport || 0) / pageSize) - 1) {
      return;
    }
    let newPage = pageNo + 1;
    setInspectionReportFilter({ ...inspectionReportFilter, pageNo: newPage });
    addDownloadReportSet(new Set());
  };

  const previousPage = () => {
    if (pageNo <= 1) {
      return;
    }
    let newPage = pageNo - 1;
    setInspectionReportFilter({ ...inspectionReportFilter, pageNo: newPage });
    addDownloadReportSet(new Set());
  };
  useEffect(() => {
    getInspectionReportListing(inspectionReportFilter);
    addDownloadReportSet(new Set());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inspectionReportFilter]);

  useEffect(() => {
    if (inspectionReportFilter.filterByType === "asset") {
      setLastFilterData(dropdownAssetList);
    } else if (inspectionReportFilter.filterByType === "assetType") {
      setLastFilterData(dropdownAssetTypeList);
    } else if (inspectionReportFilter.filterByType === "inspectedBy") {
      // New backend api

      const url = `/dropdown/users?accid=${
        isSuperAdmin ? inspectionReportFilter.accountId : loginDetails.accountId
      }&siteid=${
        isSiteAdmin ? loginDetails?.userData?.authorizedSites?.[0] : dropdownFilterWorksiteId
      }&status=true`;

      apiCall(url).then((resp) => {
        setLastFilterData(resp.data?.data?.data);
      });
    } else if (inspectionReportFilter.filterByType === "inspectionResult") {
      setLastFilterData([
        { fullName: "Pass", _id: "passed" },
        { fullName: "Fail", _id: "failed" },
        { fullName: "Timed out", _id: "timedout" },
      ]);
    }
    setInspectionReportFilter({ ...inspectionReportFilter, pageNo: 1 });
  }, []);

  useEffect(() => {
    if (!isSuperAdmin) {
      inspectionReportFilter.accountId = loginDetails && loginDetails.accountId;
      //setFilterAccountId(inspectionReportFilter.accountId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginDetails]);

  useEffect(() => {
    const startResultNumber = pageSize * (pageNo - 1);
    const endResultNumber =
      parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize) < totalInspectionReport
        ? parseInt(pageSize * (pageNo - 1)) + parseInt(pageSize)
        : totalInspectionReport;
    const currentTotalReports = endResultNumber - startResultNumber;

    if (downloadReportSet.size === currentTotalReports && currentTotalReports !== 0) {
      setSelectAll(true);
    } else {
      setIsDownloadAllSelected(false);
      setSelectAll(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downloadReportSet]);

  useEffect(() => {
    if (!showDownloadAllReportModal) {
      addDownloadReportSet(new Set());
    }
  }, [showDownloadAllReportModal]);

  useEffect(() => {
    if (exportAllInspectionCSVSuccess) {
      setShowDownloadAllReportModal(true);
      exportAllInspectionCSVResetState();
    }
  }, [exportAllInspectionCSVSuccess]);

  const refreshData = () => {
    CacheRefs.removeCacheForChecklists();
    getInspectionReportListing(inspectionReportFilter);
  };

  return (
    <>
      <Filters
        startDate={startDate}
        endDate={endDate}
        setLastFilterData={setLastFilterData}
        lastFilterData={lastFilterData}
        selectedAccount={selectedAccount}
        setSelectedAccount={setSelectedAccount}
      />
      <SaveToOptions
        getZipDownloadReport={getZipDownloadReport}
        downloadReportSet={downloadReportSet}
        isDownloadAllSelected={isDownloadAllSelected}
        getZipFileReportLoading={getZipFileReportLoading}
        exportAllInspectionCSVLoading={exportAllInspectionCSVLoading}
        exportAllInspectionCSV={exportAllInspectionCSV}
        inspectionReportList={inspectionReportList}
        refreshData={refreshData}
        currentTimeZone={currentTimeZone}
      />

      <br />

      <InspectionReportDiv>
        <InspectionReportsDataTable
          data={inspectionReportList}
          isSiteAdmin={isSiteAdmin}
          isSuperAdmin={isSuperAdmin}
          handleSelectAll={handleSelectAll}
          handleCheckboxClick={handleCheckboxClick}
          downloadReportSet={downloadReportSet}
          isAllSelected={isAllSelected}
          handlePageLength={handlePageLength}
          pageSize={pageSize}
          paginationOptions={paginationOptions}
          pageNo={pageNo}
          totalPageReport={totalInspectionReport}
          previousPage={previousPage}
          nextPage={nextPage}
          selectedAccount={selectedAccount}
          rowHeight={"370"}
        />
      </InspectionReportDiv>

      <DownloadAllReportModal
        showDownloadAllReportModal={showDownloadAllReportModal}
        setShowDownloadAllReportModal={setShowDownloadAllReportModal}
      />
    </>
  );
}

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  isSuperAdmin: state.user.isSuperAdmin,
  isSiteAdmin: state.user.isSiteAdmin,
  inspectionReportList: state.reports.inspectionReportList,
  totalInspectionReport: state.reports.inspectionReportTotalCount,
  getZipFileReportLoading: state.reports.getZipFileReportLoading,
  inspectionReportFilter: state.filters.inspectionReportFilter,
  dropdownAssetTypeList: state.dropdownFilters.assetTypeList,
  dropdownAssetList: state.dropdownFilters.assetList,
  dropdownFilterAccountId: state.dropdownFilters.accountId,
  dropdownFilterWorksiteId: state.dropdownFilters.worksiteId,
  fileDownloadLoading: state.config.fileDownloadLoading,
  exportAllInspectionCSVLoading: state.reports.exportAllInspectionCSVLoading,
  exportAllInspectionCSVSuccess: state.reports.exportAllInspectionCSVSuccess,
  exportAllInspectionCSVError: state.reports.exportAllInspectionCSVError,
  currentTimeZone: state.settings.currentTimeZone,
});

const mapDispatchToProps = (dispatch) => ({
  getInspectionReportListing: (filterData) => dispatch(getInspectionReportListing(filterData)),
  getZipDownloadReport: (filterReportArray) => dispatch(getZipDownloadReport(filterReportArray)),
  setInspectionReportFilter: (filterData) => dispatch(setInspectionReportFilter(filterData)),
  setDropdownAccountId: (accountId) => dispatch(setDropdownAccountId(accountId)),
  setDropdownWorksiteId: (worksiteId) => dispatch(setDropdownWorksiteId(worksiteId)),
  updateActiveNavItem: (item) => dispatch(updateActiveNavItem(item)),
  toDownloadFiles: (folderName, fileName) => dispatch(toDownloadFiles(folderName, fileName)),
  exportAllInspectionCSV: () => dispatch(exportAllInspectionCSV()),
  exportAllInspectionCSVResetState: () => dispatch(exportAllInspectionCSVResetState()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(InspectionReports));
