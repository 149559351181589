import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";

import { updateAccounts } from "../../../redux/accounts/action";
import DropdownFilter from "../../../components/DropdownComponent/DropdownWrapper";
import UpdateButtons from "../../../components/UpdateButtons.component";
import { ReactComponent as IconRight } from "../../../assets/AddIcon/IconRight.svg";

import { setEditForm } from "../../../redux/forms/action";
import { apiCall } from "../../../utils/apiCall";

import units from "../../../constant/units";
import {
  ManagementPanelWrapper,
  FullWidth,
  AccountPageWrapper,
  UserCol,
  Container,
  UrlText,
  AvailableText,
  NotAvailableText,
  LabelDiv,
  DivLogoCompany,
  NoLogoMessage,
  CompanyLogoImg,
} from "./AccountOverviewTab.component.styles";
import PermissionCheck from "../../../components/PermissionCheck/PermissionCheck";
import SieraTabs from "../../../components/TabComponent/SieraTabs.Component";
import SieraTab from "../../../components/TabComponent/SieraTab.Component";

const timeZone = require("../../../constant/timeZone");

import config from "../../../config";
import DropdownFilterWrapper from "../../../components/DropdownComponent/DropdownFilterWrapper";
import InputField from "../../../components/InputFieldComponent/InputField.component";

import InputStartAdorment from "./InputStartAdornmentWrapper/InputStartAdorment";

const s3BucketLogo = config.s3BucketLogo;

function AccountOverviewTab({
  AccountOverview,
  updateAccounts,
  updateAccountLoading,
  accountDetails,
  setAccountDetails,
  imageLoaded,
  setImageLoaded,
  AccountLoading,
  editForm,
  setEditForm,
}) {
  const [value, setValue] = React.useState(0);
  const [errors, setErrors] = useState(false);

  useEffect(() => {
    if (!AccountOverview) {
      return;
    }
    let { companyName, logo, enterpriseUrl, unitType, _id, timeZone } = AccountOverview;
    setAccountDetails({
      ...accountDetails,
      _id,
      companyName,
      logo,
      enterpriseUrl,
      unitType,
      timeZone,
    });
  }, [AccountOverview, updateAccountLoading]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUpdate = (event) => {
    event.preventDefault();
    if (errors.enterpriseUrl === "NotAvailable") {
      return;
    }
    let bodyFormData = new FormData();
    bodyFormData.append("accountData", JSON.stringify({ ...accountDetails }));
    updateAccounts(event.target.id, bodyFormData, setEditForm);
  };

  // Enterprise URL Check
  const handleEnterpriseUrl = (e) => {
    const fullUrl = e.target.value;
    const actualUrl = fullUrl.substring(fullUrl.indexOf("/") + 1);
    setAccountDetails({ ...accountDetails, enterpriseUrl: actualUrl });
    apiCall(`/account/urlAvailabilty/${AccountOverview._id}/${actualUrl}`)
      .then((response) => {
        if (response.data.success === true) {
          setErrors({ enterpriseUrl: "Available" });
        } else if (response.data.success === false) {
          setErrors({ enterpriseUrl: "NotAvailable" });
        }
      })
      .catch((error) => {
        setErrors({ enterpriseUrl: "NotAvailable" });
      });
  };

  const { t } = useTranslation();

  return (
    <>
      <ManagementPanelWrapper>
        <SieraTabs
          defaultActiveKey="accountOverview"
          variant="horizontalNew"
          activeKey="accountOverview"
          onSelect={handleChange}
        >
          <SieraTab eventKey="accountOverview" title={t("Account Overview")}>
            {AccountLoading && (
              <div>
                <Skeleton className="m-2" count={6} width={"90%"} />
              </div>
            )}
            {!AccountLoading && AccountOverview && (
              <>
                <AccountPageWrapper>
                  <FullWidth>
                    {!editForm && (
                      <UserCol>
                        <InputField
                          className="companyName"
                          label={"Company Name"}
                          autoFocus
                          minLength={"1"}
                          size="medium"
                          maxLength={"64"}
                          value={accountDetails.companyName}
                          onChange={(e) =>
                            setAccountDetails({
                              ...accountDetails,
                              companyName: e.target.value,
                            })
                          }
                          fullWidth
                          disabled={editForm}
                        />
                      </UserCol>
                    )}
                    <UserCol>
                      <DropdownFilterWrapper
                        key={"unitType"}
                        id={"unitType"}
                        label={"Unit Type"}
                        size="medium"
                        fullWidth
                        //   required={true}
                        name={"unitType"}
                        onChange={(value) => setAccountDetails({ ...accountDetails, unitType: value })}
                        value={accountDetails.unitType ? accountDetails.unitType : "Imperial"}
                        options={units}
                        disabled={editForm}
                      />
                    </UserCol>
                    <UserCol className="timeZone">
                      <DropdownFilter
                        disabled={editForm}
                        value={accountDetails.timeZone}
                        useAsDropdown={true}
                        filter={{
                          type: "Time Zone",
                          name: "label",
                          key: "label",
                          data: timeZone,
                        }}
                        label={t("filter")}
                        handleFilter={(value) => setAccountDetails({ ...accountDetails, timeZone: value })}
                      />
                    </UserCol>
                    <UserCol>
                      <>
                        <InputStartAdorment
                          key={"enterpriseUrl"}
                          id={"enterpriseUrl"}
                          label={t("Enterprise URL")}
                          // fullWidth
                          //required={false}
                          name={"enterpriseUrl"}
                          onChange={handleEnterpriseUrl}
                          value={accountDetails.enterpriseUrl}
                          disabled={editForm}
                          startAdornment={"manage.siera.ai/#/"}
                          maxLength="128"
                        />

                        <Container>
                          {/* <span><Required style={{marginTop:'4px'}} /></span> */}
                          <UrlText>
                            {errors.enterpriseUrl === t("Please enter enterprise url")
                              ? errors.enterpriseUrl
                              : ""}
                          </UrlText>
                          {errors.enterpriseUrl === "Available" ? (
                            <UrlText>
                              <Trans>URL Availability</Trans>: <IconRight />
                              <AvailableText style={{ color: "green" }}>
                                <Trans>You are good to go</Trans>
                              </AvailableText>
                            </UrlText>
                          ) : (
                            ""
                          )}
                          {errors.enterpriseUrl === "NotAvailable" ? (
                            <UrlText>
                              <Trans>URL Availability</Trans>:{" "}
                              <NotAvailableText style={{ color: "red" }}>
                                <Trans>Enterprise URL is not available</Trans>
                              </NotAvailableText>
                            </UrlText>
                          ) : (
                            ""
                          )}
                        </Container>
                      </>
                    </UserCol>

                    <UserCol className="accountLogo">
                      <LabelDiv>
                        <Trans>Account Logo</Trans>
                      </LabelDiv>
                      <DivLogoCompany className="div_logo_company">
                        {AccountOverview && AccountOverview.logo ? (
                          <>
                            <CompanyLogoImg
                              alt="company logo"
                              src={
                                AccountOverview && AccountOverview.logo
                                  ? s3BucketLogo + AccountOverview.logo
                                  : ""
                              }
                              loaded={imageLoaded}
                              onLoad={() => setImageLoaded(true)}
                            />
                            {!imageLoaded && (
                              <div
                                className="spinner-border"
                                style={{ marginTop: "12%" }}
                                role="status"
                              ></div>
                            )}
                          </>
                        ) : (
                          <NoLogoMessage>No logo available</NoLogoMessage>
                        )}
                      </DivLogoCompany>
                    </UserCol>
                  </FullWidth>
                </AccountPageWrapper>

                <PermissionCheck section="DASHBOARD" permissionName={"ACCOUNT_MANAGEMENT"} actionName="edit">
                  <UpdateButtons
                    editOverview={editForm}
                    setEditOverview={setEditForm}
                    statusButton={true}
                    handleUpdate={handleUpdate}
                    IsLoading={updateAccountLoading}
                    overviewDetail={AccountOverview}
                  />
                </PermissionCheck>
              </>
            )}
          </SieraTab>
        </SieraTabs>
      </ManagementPanelWrapper>
    </>
  );
}

const mapStateToProps = (state) => ({
  AccountOverview: state.accounts.AccountOverview,
  updateAccountLoading: state.accounts.UpdateAccountLoading,
  AccountLoading: state.accounts.AccountLoading,
  editForm: state.forms.editForm,
});

const mapDispatchToProps = (dispatch) => ({
  updateAccounts: (id, data, setEditForm) => dispatch(updateAccounts(id, data, setEditForm)),
  setEditForm: (data) => dispatch(setEditForm(data)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccountOverviewTab));
