let timeZone = [
  { label: "Dateline Standard Time", _id: "-12:00 International Date Line West", short: "DST" },
  { label: "UTC-11", _id: "-11:00 Coordinated Universal Time-11", short: "UTC-11" },
  { label: "Hawaiian Standard Time", _id: "-10:00 Hawaii", short: "HST" },
  { label: "Alaskan Standard Time", _id: "-09:00 Alaska", short: "AKST" },
  { label: "Alaskan Daylight Time", _id: "-08:00 Alaska", short: "AKDT" },
  { label: "Pacific Standard Time", _id: "-08:00 Pacific Time (US & Canada)", short: "PST" },
  { label: "Pacific Daylight Time", _id: "-07:00 Pacific Time (US & Canada)", short: "PDT" },
  { label: "Pacific Standard Time (Mexico)", _id: "-08:00 Baja California", short: "PST" },
  { label: "Arizona Mountain Standard Time", _id: "-07:00 Arizona", short: "MST" },
  { label: "Mountain Standard Time", _id: "-07:00 Mountain Time (US & Canada)", short: "MST" },
  { label: "Mountain Daylight Time", _id: "-06:00 Mountain Time (US & Canada)", short: "MDT" },
  { label: "Mountain Standard Time (Mexico)", _id: "-07:00 Chihuahua, La Paz, Mazatlan", short: "MSTM" },
  { label: "Central Standard Time", _id: "-06:00 Central Time (US & Canada)", short: "CST" },
  { label: "Central Daylight Time", _id: "-05:00 Central America", short: "CDT" },
  {
    label: "Central Standard Time (Mexico)",
    _id: "-06:00 Guadalajara, Mexico City, Monterrey",
    short: "CST",
  },
  { label: "Canada Central Standard Time", _id: "-06:00 Saskatchewan", short: "CST" },
  { label: "SA Pacific Standard Time", _id: "-05:00 Bogota, Lima, Quito", short: "SAPST" },
  { label: "Eastern Standard Time", _id: "-05:00 Eastern Time (US & Canada)", short: "EST" },
  { label: "Eastern Daylight Time", _id: "-04:00 Eastern Time (US & Canada)", short: "EDT" },
  { label: "Venezuela Standard Time", _id: "-04:30 Caracas", short: "VET" },
  { label: "Paraguay Standard Time", _id: "-04:00 Asuncion", short: "PYT" },
  { label: "Atlantic Standard Time", _id: "-04:00 Atlantic Time (Canada)", short: "AST" },
  { label: "Central Brazilian Standard Time", _id: "-04:00 Cuiaba", short: "CBT" },
  { label: "SA Western Standard Time", _id: "-04:00 Georgetown, La Paz, Manaus, San Juan", short: "SAWST" },
  { label: "Pacific SA Standard Time", _id: "-04:00 Santiago", short: "CLT" },
  { label: "Newfoundland Standard Time", _id: "-03:30 Newfoundland", short: "NST" },
  { label: "E. South America Standard Time", _id: "-03:00 Brasilia", short: "BRT" },
  { label: "Argentina Standard Time", _id: "-03:00 Buenos Aires", short: "ART" },
  { label: "SA Eastern Standard Time", _id: "-03:00 Cayenne, Fortaleza", short: "SAEST" },
  { label: "Greenland Standard Time", _id: "-03:00 Greenland", short: "GLT" },
  { label: "Montevideo Standard Time", _id: "-03:00 Montevideo", short: "UYT" },
  { label: "Bahia Standard Time", _id: "-03:00 Salvador", short: "BST" },
  { label: "UTC-02", _id: "-02:00 Coordinated Universal Time-02", short: "UTC-02" },
  { label: "Mid-Atlantic Standard Time", _id: "-02:00 Mid-Atlantic - Old", short: "MAT" },
  { label: "Azores Standard Time", _id: "-01:00 Azores", short: "AZOT" },
  { label: "Cape Verde Standard Time", _id: "-01:00 Cape Verde Is.", short: "CVT" },
  { label: "Morocco Standard Time", _id: "00:00 Casalanca", short: "WET" },
  { label: "UTC", _id: "+00:00 Coordinated Universal Time", short: "UTC" },
  { label: "GMT Standard Time, Edinburgh", _id: "00:00 Edinburgh, London", short: "GMT" },
  { label: "British Summer Time", _id: "+01:00 Edinburgh, London", short: "BST" },
  { label: "GMT Standard Time, Dublin", _id: "00:00 Dubln, Lisbon", short: "GMT" },
  { label: "Greenwich Standard Time", _id: "00:00 Monrvia, Reykjavik", short: "GMT" },
  {
    label: "Central Europe Standard Time",
    _id: "+01:00 Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna, Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    short: "CET",
  },
  {
    label: "Central Europe Daylight Time",
    _id: "+02:00 Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna, Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    short: "CEDT",
  },
  { label: "Romance Standard Time", _id: "+01:00 Brussels, Copenhagen, Madrid, Paris", short: "RST" },
  { label: "W. Central Africa Standard Time", _id: "+01:00 West Central Africa", short: "WAT" },
  { label: "Namibia Standard Time", _id: "+01:00 Windhoek", short: "NST" },
  { label: "GTB Standard Time", _id: "+02:00 Athens, Bucharest", short: "EET" },
  { label: "Middle East Standard Time", _id: "+02:00 Beirut", short: "EET" },
  { label: "Egypt Standard Time", _id: "+02:00 Cairo", short: "EET" },
  { label: "Syria Standard Time", _id: "+02:00 Damascus", short: "EET" },
  { label: "E. Europe Standard Time", _id: "+02:00 E. Europe", short: "EET" },
  { label: "South Africa Standard Time", _id: "+02:00 Harare, Pretoria", short: "SAST" },
  { label: "FLE Standard Time", _id: "+02:00 Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", short: "EET" },
  { label: "Turkey Standard Time", _id: "+03:00 Istanbul", short: "TRT" },
  { label: "Israel Standard Time", _id: "+02:00 Jerusalem", short: "IST" },
  { label: "Libya Standard Time", _id: "+02:00 Tripoli", short: "EET" },
  { label: "Jordan Standard Time", _id: "+03:00 Amman", short: "AST" },
  { label: "Arabic Standard Time", _id: "+03:00 Baghdad", short: "AST" },
  { label: "Kaliningrad Standard Time", _id: "+02:00 Kaliningrad", short: "EET" },
  { label: "Arab Standard Time", _id: "+03:00 Kuwait, Riyadh", short: "AST" },
  { label: "E. Africa Standard Time", _id: "+03:00 Nairobi", short: "EAT" },
  { label: "Moscow Standard Time", _id: "+03:00 Moscow, St. Petersburg, Volgograd, Minsk", short: "MSK" },
  { label: "Samara Time", _id: "+04:00 Samara, Ulyanovsk, Saratov", short: "SAMT" },
  { label: "Iran Standard Time", _id: "+03:30 Tehran", short: "IRST" },
  { label: "Arabian Standard Time", _id: "+04:00 Abu Dhabi, Muscat", short: "GST" },
  { label: "Azerbaijan Standard Time", _id: "+04:00 Baku", short: "AZT" },
  { label: "Mauritius Standard Time", _id: "+04:00 Port Louis", short: "MUT" },
  { label: "Georgian Standard Time", _id: "+04:00 Tbilisi", short: "GET" },
  { label: "Caucasus Standard Time", _id: "+04:00 Yerevan", short: "AMT" },
  { label: "Afghanistan Standard Time", _id: "+04:30 Kabul", short: "AFT" },
  { label: "West Asia Standard Time", _id: "+05:00 Ashgabat, Tashkent", short: "UZT" },
  { label: "Yekaterinburg Time", _id: "+05:00 Yekaterinburg", short: "YEKT" },
  { label: "Pakistan Standard Time", _id: "+05:00 Islamabad, Karachi", short: "PKT" },
  { label: "India Standard Time", _id: "+05:30 Chennai, Kolkata, Mumbai, New Delhi", short: "IST" },
  { label: "Sri Lanka Standard Time", _id: "+05:30 Sri Jayawardenepura", short: "SLST" },
  { label: "Nepal Standard Time", _id: "+05:45 Kathmandu", short: "NPT" },
  { label: "Central Asia Standard Time", _id: "+06:00 Nur-Sultan (Astana)", short: "CAT" },
  { label: "Bangladesh Standard Time", _id: "+06:00 Dhaka", short: "BST" },
  { label: "Myanmar Standard Time", _id: "+06:30 Yangon (Rangoon)", short: "MMT" },
  { label: "SE Asia Standard Time", _id: "+07:00 Bangkok, Hanoi, Jakarta", short: "ICT" },
  { label: "N. Central Asia Standard Time", _id: "+07:00 Novosibirsk", short: "NOVT" },
  { label: "China Standard Time", _id: "+08:00 Beijing, Chongqing, Hong Kong, Urumqi", short: "CST" },
  { label: "North Asia Standard Time", _id: "+08:00 Krasnoyarsk", short: "KRAT" },
  { label: "Singapore Standard Time", _id: "+08:00 Kuala Lumpur, Singapore", short: "SGT" },
  { label: "W. Australia Standard Time", _id: "+08:00 Perth", short: "AWST" },
  { label: "Taipei Standard Time", _id: "+08:00 Taipei", short: "CST" },
  { label: "Ulaanbaatar Standard Time", _id: "+08:00 Ulaanbaatar", short: "ULAT" },
  { label: "North Asia East Standard Time", _id: "+08:00 Irkutsk", short: "IRKT" },
  { label: "Japan Standard Time", _id: "+09:00 Osaka, Sapporo, Tokyo", short: "JST" },
  { label: "Korea Standard Time", _id: "+09:00 Seoul", short: "KST" },
  { label: "Cen. Australia Standard Time", _id: "+09:30 Adelaide", short: "ACST" },
  { label: "AUS Central Standard Time", _id: "+09:30 Darwin", short: "ACDT" },
  { label: "E. Australia Standard Time", _id: "+10:00 Brisbane", short: "AEST" },
  { label: "AUS Eastern Standard Time", _id: "+10:00 Canberra, Melbourne, Sydney", short: "AEST" },
  { label: "West Pacific Standard Time", _id: "+10:00 Guam, Port Moresby", short: "WPST" },
  { label: "Tasmania Standard Time", _id: "+10:00 Hobart", short: "AEDT" },
  { label: "Yakutsk Standard Time", _id: "+09:00 Yakutsk", short: "YAKT" },
  { label: "Central Pacific Standard Time", _id: "+11:00 Solomon Is., New Caledonia", short: "CPST" },
  { label: "Vladivostok Standard Time", _id: "+11:00 Vladivostok", short: "VLAT" },
  { label: "New Zealand Standard Time", _id: "+12:00 Auckland, Wellington", short: "NZST" },
  { label: "UTC+12", _id: "+12:00 Coordinated Universal Time+12", short: "UTC+12" },
  { label: "Fiji Standard Time", _id: "+12:00 Fiji", short: "FJT" },
  { label: "Magadan Standard Time", _id: "+12:00 Magadan", short: "MAGT" },
  { label: "Kamchatka Standard Time", _id: "+12:00 Petropavlovsk-Kamchatsky - Old", short: "PETT" },
  { label: "Tonga Standard Time", _id: "+13:00 Nuku'alofa", short: "TOT" },
  { label: "Samoa Standard Time", _id: "+13:00 Samoa", short: "SST" },
];

export default timeZone;
