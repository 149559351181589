import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { CarouselWrapperDiv } from "./AccountCard.component.styles";
import CarouselComponent from "../../../components/CarouselComponent/Carousel.component";

const carouselData = [
  {
    name: "Accounts",
    iconClass: "accounts",
    feildName: "accounts",
    type: "ThemePrimary",
    iconColor: "GrayL4",
  },
  {
    name: "Worksites",
    iconClass: "worksites",
    feildName: "sites",
    type: "ThemePrimary",
    iconColor: "GrayL4",
  },
  {
    name: "Assets",
    iconClass: "assets",
    feildName: "assets",
    type: "ThemePrimary",
    iconColor: "GrayL4",
  },
  {
    name: "Users",
    iconClass: "users",
    feildName: "users",
    type: "ThemePrimary",
    iconColor: "GrayL4",
  },
];

const AccountCard = ({ count }) => (
  <>
    <CarouselWrapperDiv>
      <CarouselComponent
        countData={count}
        carouselData={carouselData}
        swipeable={true}
        autoPlay={true}
        autoPlaySpeed={5000}
        infinite={true}
        slidesToSlide={1}
        size="small"
      />
    </CarouselWrapperDiv>
  </>
);

const mapStateToProps = (state) => ({
  loginDetails: state.user.loginDetails,
  count: state.counts.allCount,
});

export default withRouter(connect(mapStateToProps)(AccountCard));
