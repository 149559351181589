import React, { useState, useRef, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";

import {
  TimezoneMenuWrapper,
  MenuItem,
  TimezoneWrapper,
  TimeSelected,
  IconDrop,
  TimezoneDiv,
} from "./Timezone.component.styles";
import ButtonIcon from "../../../../components/IconButtonComponent/IconButton.component";
import timeZone from "../../../../constant/timeZone";
import DropdownField from "../../../../components/DropdownComponent/DropdownFilterWrapper";
import { setSelectedTimeZone } from "../../../../redux/settings/settings.actions";

const TimezoneMenu = ({ setSelectedTimeZone, selectedTimeZone, userTimeZone }) => {
  const [showTimezoneMenu, setShowTimezoneMenu] = useState(false);
  const [clickedOutside, setClickedOutside] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (userTimeZone) {
      setSelectedTimeZone(userTimeZone);
      localStorage.setItem("currentTimezone", userTimeZone);
    } else {
      const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const timeZoneName = moment.tz(localTimezone).zoneAbbr();
      const localTimeZoneObj = timeZone.find((zone) => zone.short === timeZoneName);

      setSelectedTimeZone(localTimeZoneObj._id);
      localStorage.setItem("currentTimezone", localTimeZoneObj._id);
    }
  }, [userTimeZone]);

  const clickHandler = () => {
    setShowTimezoneMenu(!showTimezoneMenu);
  };

  useEffect(() => {
    if (!clickedOutside) return;
    setShowTimezoneMenu(false);
    setClickedOutside(false);
  }, [clickedOutside]);

  const myRef = useRef();

  const handleClickOutside = (e) => {
    if (myRef.current && !myRef.current.contains(e.target)) {
      setClickedOutside(true);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  const handleTimeZoneChange = (value) => {
    localStorage.setItem("currentTimezone", value);
    setSelectedTimeZone(value);
    setShowTimezoneMenu(false);
    setClickedOutside(false);
  };

  const getTimeZoneLabel = (id) => {
    const letter = timeZone.find((zone) => zone._id === id);
    return letter?.short;
  };

  const TextWithDropDown = () => {
    return (
      <TimeSelected>
        <TimezoneDiv>{getTimeZoneLabel(selectedTimeZone || userTimeZone)} </TimezoneDiv>
        <IconDrop className="icon icon-arrow-down"></IconDrop>
      </TimeSelected>
    );
  };

  return (
    <TimezoneWrapper ref={myRef}>
      <ButtonIcon icon="icon-timezone" label="Timezone" onClick={clickHandler} textDrop={true}>
        <TextWithDropDown />
      </ButtonIcon>
      <TimezoneMenuWrapper showTimezoneMenu={showTimezoneMenu}>
        <MenuItem>
          <DropdownField
            id="timeZone"
            label={t("Current Timezone")}
            fullWidth
            name="timeZone"
            onChange={handleTimeZoneChange}
            value={selectedTimeZone || userTimeZone}
            options={timeZone}
          />
        </MenuItem>
      </TimezoneMenuWrapper>
    </TimezoneWrapper>
  );
};

const mapStateToProps = (state) => ({
  selectedTimeZone: state.settings.currentTimeZone,
  userTimeZone: state.user?.loginDetails?.userData?.timezone,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedTimeZone: (timeZone) => dispatch(setSelectedTimeZone(timeZone)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TimezoneMenu);
